<template>
    <v-layout justify-end mx-5 mb-2>
        <v-flex sm11 xs12>
            <v-card :elevation="0" height="100%">
                <v-card-title>
                    <h6 class="text-h6 font-weight-thin primary--text">
                        {{ $t("Individueller Export") }}
                    </h6>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="filter.languages"
                                :items="languages"
                                attach
                                chips
                                :label="$t('Languages')"
                                multiple
                                item-value="iso"
                                item-text="label"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="7">
                            <v-autocomplete
                                v-model="filter.products"
                                :items="products"
                                attach
                                chips
                                :label="$t('Products')"
                                multiple
                                :search-input.sync="search"
                                item-value="id"
                                :filter="filterProducts"
                                :loading="searchLoading"
                                clearable
                                deletable-chips
                                cache-items
                                :placeholder="
                                    $t(
                                        'Mit der Eingabe beginnen um zu suchen ...'
                                    )
                                "
                                :item-text="`item_number`"
                                @select="search = ''"
                            >
                                <template #item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="item.name[selectedLanguage]"
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="
                                                `${$t('Product no')}: ${
                                                    item.item_number
                                                }`
                                            "
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                                <template #no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                            {{
                                                $t(
                                                    "Start typing to search products."
                                                )
                                            }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-expansion-panels flat>
                                <v-expansion-panel class="px-0">
                                    <v-expansion-panel-header class="px-0">
                                        <template #default="{ open }">
                                            <v-row no-gutters>
                                                <v-col cols="4" class="px-0">
                                                    {{ $t("Fields") }}
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col
                                                    cols="2"
                                                    class="text--secondary"
                                                >
                                                    <v-fade-transition
                                                        leave-absolute
                                                    >
                                                        <span v-if="!open">
                                                            {{
                                                                filter.fields
                                                                    .length
                                                            }}
                                                            {{
                                                                $t(
                                                                    "fields selected"
                                                                )
                                                            }}
                                                        </span>
                                                    </v-fade-transition>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="px-0">
                                        <v-row>
                                            <v-col class="px-0">
                                                <v-card-text class="px-0">
                                                    <v-treeview
                                                        v-model="filter.fields"
                                                        :items="items"
                                                        selected-color="primary"
                                                        open-on-click
                                                        selectable
                                                        :return-object="false"
                                                        expand-icon="mdi-chevron-down"
                                                        item-disabled="locked"
                                                        on-icon="mdi-checkbox-marked"
                                                        off-icon="mdi-checkbox-blank-outline"
                                                        indeterminate-icon="mdi-minus-box"
                                                    >
                                                    </v-treeview>
                                                </v-card-text>
                                            </v-col>

                                            <v-divider vertical></v-divider>

                                            <v-col cols="12" md="6">
                                                <v-card-text>
                                                    <div
                                                        v-if="tree.length === 0"
                                                        key="title"
                                                        class="text-h6 font-weight-light grey--text pa-4 text-center"
                                                    >
                                                        {{ $t("Selection") }}
                                                    </div>

                                                    <v-scroll-x-transition
                                                        group
                                                        hide-on-leave
                                                    >
                                                        <v-chip
                                                            v-for="(selection,
                                                            i) in filter.fields"
                                                            :key="i"
                                                            color="grey"
                                                            dark
                                                            small
                                                            class="ma-1"
                                                        >
                                                            <v-icon left small>
                                                                mdi-format-columns
                                                            </v-icon>
                                                            {{ selection }}
                                                        </v-chip>
                                                    </v-scroll-x-transition>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-divider light class="black-divider" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="mt-5 ml-2"
                        color="primary"
                        :loading="loading"
                        @click="download"
                        >Download</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {
    api,
    availableLanguages,
    defaultLanguageIso,
    mandatoryLanguages
} from "~/config";

export default {
    data() {
        return {
            filter: {
                products: [],
                languages: [],
                fields: ["item_number", "name"]
            },
            isLoading: false,
            tree: [],
            types: [],
            selectedLanguage: defaultLanguageIso,
            selectedTenants: [],
            crumbs: [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: this.$t("Export"),
                    disabled: true,
                    href: "/products/export"
                }
            ],
            loading: false,
            search: null,
            products: [],
            searchLoading: false,
            pagination: {
                rowsPerPage: -1
            },
            dialog: false,
            languages: availableLanguages,
            defaultLanguageIso: defaultLanguageIso,
            mandatoryLanguages: mandatoryLanguages,
            headers: [
                {
                    text: this.$t("Item number"),
                    value: "item_number",
                    sortable: false
                },
                {
                    text: this.$t("Status web"),
                    value: "export_web",
                    sortable: false
                },
                {
                    text: this.$t("Ready translations"),
                    value: "ready_translations",
                    sortable: false
                },
                {
                    text: this.$t("Tenant"),
                    value: "tenant.name",
                    sortable: false
                },
                { text: this.$t("Name"), value: "name", sortable: false },
                {
                    text: this.$t("Action"),
                    value: "action",
                    align: "right",
                    sortable: false
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            exportProducts: "products/productExports",
            listTotalCount: "products/listTotalCount",
            productFilter: "ui/productFilter",
            tenants: "master-data/tenants",
            tenant: "auth/tenant"
        }),
        selectedAllFields() {
            return (
                this.filter.fields.length ===
                this.fields.filter(i => i.key).length
            );
        },
        selectedSomeFields() {
            return this.filter.fields.length > 0 && !this.selectedAllFields;
        },
        icon() {
            if (this.selectedAllFields) return "mdi-close-box";
            if (this.selectedSomeFields) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        },
        items() {
            const children = [
                {
                    id: 2,
                    name: this.$t("Basics"),
                    children: [
                        {
                            id: "tenant_id",
                            name: this.$t("Tenant")
                        },
                        {
                            id: "item_number",
                            name: this.$t("Product no"),
                            locked: true
                        },
                        {
                            id: "brand_id",
                            name: this.$t("Brand")
                        },
                        {
                            id: "product_group_id",
                            name: this.$t("Product group")
                        },
                        {
                            id: "product_group_code_id",
                            name: this.$t("Product group code")
                        },
                        {
                            id: "gtin",
                            name: this.$t("GTIN")
                        },
                        {
                            id: "name",
                            name: this.$t("Name"),
                            locked: true
                        },
                        {
                            id: "extended_name",
                            name: this.$t("Extended name")
                        },
                        {
                            id: "registry_numbers",
                            name: this.$t("Registry numbers")
                        },
                        {
                            id: "slug",
                            name: this.$t("slug")
                        },
                        {
                            id: "qr_code_url",
                            name: this.$t("QR Code URL")
                        },
                        {
                            id: "unit_price",
                            name: this.$t("Unit price")
                        },
                        {
                            id: "vat_id",
                            name: this.$t("VAT Group")
                        },
                        {
                            id: "export_web",
                            name: this.$t("Export web")
                        },
                        {
                            id: "shoppable",
                            name: this.$t("shoppable")
                        },
                        {
                            id: "is_new",
                            name: this.$t("is_new")
                        },
                        {
                            id: "show_calculator",
                            name: this.$t("Show calculator")
                        }
                    ]
                },
                {
                    id: 3,
                    name: this.$t("Logistics"),
                    children: [
                        {
                            id: "weight_gross",
                            name: this.$t("Gross Weight")
                        },
                        {
                            id: "weight_net",
                            name: this.$t("Net Weight")
                        },
                        {
                            id: "weight_of_pallet",
                            name: this.$t("Weight of Pallet")
                        },
                        {
                            id: "dimensions",
                            name: this.$t("Dimensions")
                        },
                        {
                            id: "quantity_per_package",
                            name: this.$t("Quantity of package")
                        },
                        {
                            id: "quantity_of_pallet",
                            name: this.$t("Quantity of pallet")
                        },
                        {
                            id: "minimum_order_quantity",
                            name: this.$t("Minimum order quantitiy")
                        },
                        {
                            id: "inventory",
                            name: this.$t("Inventory")
                        },
                        {
                            id: "inventory_per_piece",
                            name: this.$t("Inventory per piece")
                        },
                        {
                            id: "customs_tariff_number",
                            name: this.$t("Customs tariff number")
                        },
                        {
                            id: "ean_for_repackaging",
                            name: this.$t("EAN for repackaging")
                        },
                        {
                            id: "transport_regulations",
                            name: this.$t("Transport regulations")
                        }
                    ]
                },
                {
                    id: 4,
                    name: this.$t("Texts"),
                    children: [
                        {
                            id: "description_short",
                            name: this.$t("Description short")
                        },
                        {
                            id: "features_text",
                            name: this.$t("Features")
                        },
                        {
                            id: "description_long",
                            name: this.$t("Description long")
                        },
                        {
                            id: "application_text",
                            name: this.$t("Application")
                        },
                        {
                            id: "dosage_text",
                            name: this.$t("Dosage")
                        },
                        {
                            id: "dosage_exact",
                            name: this.$t("Dosage simple")
                        },
                        {
                            id: "dosage_unit",
                            name: this.$t("Dosage unit")
                        },
                        {
                            id: "dosage_min",
                            name: this.$t("Dosage min")
                        },
                        {
                            id: "dosage_max",
                            name: this.$t("Dosage max")
                        },
                        {
                            id: "take_note",
                            name: this.$t("Take note")
                        },
                        {
                            id: "storage_note_id",
                            name: this.$t("Storage notes")
                        },
                        {
                            id: "disposal_note_id",
                            name: this.$t("Disposal notes")
                        },
                        {
                            id: "emergency_information_id",
                            name: this.$t("Emergency information")
                        },
                        {
                            id: "special_text",
                            name: this.$t("Special text")
                        },
                        {
                            id: "marketing_text",
                            name: this.$t("Marketing text")
                        },
                        {
                            id: "label_1_text",
                            name: this.$t("Labeltext 1")
                        },
                        {
                            id: "label_2_text",
                            name: this.$t("Labeltext 2")
                        }
                    ]
                },
                {
                    id: 5,
                    name: this.$t("Media"),
                    children: [
                        {
                            id: "image",
                            name: this.$t("image")
                        },
                        {
                            id: "datasheet",
                            name: this.$t("Datasheet")
                        },
                        {
                            id: "safety_data_sheet",
                            name: this.$t("Safety Datasheet")
                        }
                    ]
                },
                {
                    id: 6,
                    name: this.$t("Attributes"),
                    children: [
                        {
                            id: "chemical_type_id",
                            name: this.$t("Chemical type")
                        },
                        {
                            id: "seasons",
                            name: this.$t("Seasons")
                        },
                        {
                            id: "characteristics",
                            name: this.$t("Characteristics")
                        },
                        {
                            id: "effect_duration_id",
                            name: this.$t("Effect duration")
                        },
                        {
                            id: "plant_groups",
                            name: this.$t("Plant groups")
                        },
                        {
                            id: "plants",
                            name: this.$t("Plants")
                        },
                        {
                            id: "tasks",
                            name: this.$t("Tasks")
                        },
                        {
                            id: "product_line_id",
                            name: this.$t("Product line")
                        },
                        {
                            id: "application_sites",
                            name: this.$t("Application sites")
                        },
                        {
                            id: "application_scopes",
                            name: this.$t("Application scopes")
                        },
                        {
                            id: "task_areas",
                            name: this.$t("Task areas")
                        },
                        {
                            id: "purposes",
                            name: this.$t("Purposes")
                        },
                        {
                            id: "hazard_statements",
                            name: this.$t("Hazard statements")
                        },
                        {
                            id: "precautionary_statements",
                            name: this.$t("Precautionary statements")
                        },
                        {
                            id: "grain_size",
                            name: this.$t("Grain size")
                        },
                        {
                            id: "coverage",
                            name: this.$t("Coverage")
                        }
                    ]
                },
                {
                    id: 7,
                    name: this.$t("Declarations"),
                    children: [
                        {
                            id: "nutrients",
                            name: this.$t("Nutrients")
                        },
                        {
                            id: "base_substances",
                            name: this.$t("Base substances")
                        },
                        {
                            id: "minor_components",
                            name: this.$t("Minor components")
                        },
                        {
                            id: "distribution_id",
                            name: this.$t("Distribution")
                        }
                    ]
                },
                {
                    id: 8,
                    name: this.$t("Labels"),
                    children: [
                        {
                            id: "is_ec_fertilizer",
                            name: this.$t("EC-fertilizer")
                        },
                        {
                            id: "is_fibl_listed",
                            name: this.$t("FiBL listed")
                        },
                        {
                            id: "organic_labels",
                            name: this.$t("Organic labels")
                        },
                        {
                            id: "hazard_symbols",
                            name: this.$t("Hazard symbols")
                        }
                    ]
                }
            ];

            return [
                {
                    id: 1,
                    name: this.$t("All fields"),
                    children
                }
            ];
        },
        shouldShowTree() {
            return this.fields.length > 0 && !this.isLoading;
        }
    },

    watch: {
        search(val) {
            if (!val || val.length < 3) return;

            this.fetchEntriesDebounced(val);
        }
    },

    created() {
        this.getTenants();
    },

    methods: {
        ...mapActions({
            fetchExportList: "products/fetchExportList",
            getTenants: "master-data/getTenants",
            setStatus: "products/setStatus",
            setProductFilter: "ui/setProductFilter"
        }),

        filterProducts(item, queryText) {
            return (
                item.item_number
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.name[this.selectedLanguage]
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },

        fetchEntriesDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerId);

            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.products = [];

                this.searchLoading = true;

                axios
                    .get(
                        api.path("products.queryWithinTenant", {
                            term: val,
                            tenant: this.tenant
                        })
                    )
                    .then(r => r.data)
                    .then(products => {
                        this.products = products.data;
                    })
                    .finally(() => (this.searchLoading = false));
            }, 500);
        },

        async download() {
            let appendUrl = "";

            if (this.filter.languages.length < 1) {
                this.$store.dispatch("ui/showSnackbar", {
                    text: this.$t(
                        "Mindestens eine Sprache muss ausgewählt sein."
                    ),
                    color: "error"
                });
                return;
            }

            this.loading = true;

            let payload = {
                fields: this.filter.fields,
                languages: this.filter.languages,
                products: this.filter.products,
                tenant: this.tenant
            };

            if (payload) {
                if (payload.fields !== undefined && payload.fields.length > 0) {
                    appendUrl += "&fields=" + payload.fields.join(",");
                }

                if (payload.tenant !== undefined) {
                    appendUrl += "&tenant=" + payload.tenant;
                }

                if (
                    payload.languages !== undefined &&
                    payload.languages.length > 0
                ) {
                    appendUrl += "&languages=" + payload.languages.join(",");
                }

                if (
                    payload.products !== undefined &&
                    payload.products.length > 0
                ) {
                    appendUrl += "&products=" + payload.products.join(",");
                }
            }

            axios({
                url: api.path("products.customExport") + appendUrl,
                method: "GET",
                responseType: "blob"
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "products.csv");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Es ist ein Fehler aufgetreten!"),
                        color: "error"
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.black-divider {
    border-color: rgba(0, 0, 0, 0.4);
}

.expansion-header {
    color: rgba(0, 0, 0, 0.6);
}

.v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
}
</style>
