<template>
    <v-menu v-model="menu" :close-on-content-click="false" offset-y>
        <template #activator="{ on }">
            <v-btn
                rounded
                :color="filterCount < 1 ? 'grey--darken3' : 'accent'"
                outlined
                class="mr-5 mt-5"
                v-on="on"
            >
                Filter
                {{ filterCount > 0 ? "(" + filterCount + ")" : "" }}
            </v-btn>
        </template>

        <v-card>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Newly imported")
                        }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            :class="
                                value.indexOf('newly-imported') > -1
                                    ? 'accent--text'
                                    : 'grey--text'
                            "
                            icon
                            @click="toggleFilter('newly-imported')"
                        >
                            <v-icon>check_circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Unpublished")
                        }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            :class="
                                value.indexOf('unpublished') > -1
                                    ? 'accent--text'
                                    : 'grey--text'
                            "
                            icon
                            @click="toggleFilter('unpublished')"
                        >
                            <v-icon>check_circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Published")
                        }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            :class="
                                value.indexOf('published') > -1
                                    ? 'accent--text'
                                    : 'grey--text'
                            "
                            icon
                            @click="toggleFilter('published')"
                        >
                            <v-icon>check_circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Newly unpublished")
                        }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            :class="
                                value.indexOf('newly-unpublished') > -1
                                    ? 'accent--text'
                                    : 'grey--text'
                            "
                            icon
                            @click="toggleFilter('newly-unpublished')"
                        >
                            <v-icon>check_circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Highlighted")
                        }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            :class="
                                value.indexOf('highlighted') > -1
                                    ? 'accent--text'
                                    : 'grey--text'
                            "
                            icon
                            @click="toggleFilter('highlighted')"
                        >
                            <v-icon>check_circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Is new")
                        }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                            :class="
                                value.indexOf('is_new') > -1
                                    ? 'accent--text'
                                    : 'grey--text'
                            "
                            icon
                            @click="toggleFilter('is_new')"
                        >
                            <v-icon>check_circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item>
                    <v-list-item-action>
                        <v-combobox
                            v-model="selectedBrand"
                            :items="brands ? brands : []"
                            clearable
                            :label="$t('Brand')"
                            item-text="name"
                            dense
                            @change="updateBrandFilter"
                            @click.clear="updateBrandFilter"
                        ></v-combobox>
                    </v-list-item-action>
                </v-list-item>


            </v-list>

            <v-divider />

            <v-card-actions>
                <v-btn text block @click="menu = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { availableLanguages, defaultLanguageIso } from "~/config";

const compareArrays = (a, b)  => a.length === b.length && a.every((element, index) => element === b[index]);

export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        selectedBrands: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        menu: false,
        selectedBrand: null,
        activeFilters: [],
        selectedLanguage: defaultLanguageIso,
        defaultLanguageIso: defaultLanguageIso,
        languages: availableLanguages,
    }),

    computed: {
        ...mapGetters({
            brands: "master-data/brand",
            tenant: "auth/tenant"
        }),
        filterCount() {
            const filtered = this.value.filter(i => typeof i !== "object");
            let cFilter = filtered.length;
            if(this.selectedBrands.length > 0){
                cFilter++;
            }
            return cFilter;
        }
    },

    mounted: function () {
        if (this.brands.length < 1) {
            this.getData({ entity: "brand", tenant: this.tenant });
        }
    },
    
    methods: {
        ...mapActions({
            getData: "master-data/getData"
        }),
        
        updateBrandFilter() {    
            const v = this.selectedBrand ? [this.selectedBrand.id] : [];
            const c = compareArrays(this.selectedBrands, v);
            !c && this.$emit("brand-select", v);
            
        },
        toggleFilter(filter) {
            let index = this.value.findIndex(i => i === filter);
            let newValue = [...this.value];
            if (index > -1) {
                newValue.splice(index, 1);
            } else {
                newValue.push(filter);
            }
            this.$emit("input", newValue);
        }
    }
};
</script>
