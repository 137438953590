var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 mt-5",attrs:{"rounded":"","color":_vm.filterCount < 1 ? 'grey--darken3' : 'accent',"outlined":""}},on),[_vm._v(" Filter "+_vm._s(_vm.filterCount > 0 ? "(" + _vm.filterCount + ")" : "")+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Newly imported")))])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.value.indexOf('newly-imported') > -1
                                ? 'accent--text'
                                : 'grey--text',attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFilter('newly-imported')}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Unpublished")))])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.value.indexOf('unpublished') > -1
                                ? 'accent--text'
                                : 'grey--text',attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFilter('unpublished')}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Published")))])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.value.indexOf('published') > -1
                                ? 'accent--text'
                                : 'grey--text',attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFilter('published')}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Newly unpublished")))])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.value.indexOf('newly-unpublished') > -1
                                ? 'accent--text'
                                : 'grey--text',attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFilter('newly-unpublished')}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Highlighted")))])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.value.indexOf('highlighted') > -1
                                ? 'accent--text'
                                : 'grey--text',attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFilter('highlighted')}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Is new")))])],1),_c('v-list-item-action',[_c('v-btn',{class:_vm.value.indexOf('is_new') > -1
                                ? 'accent--text'
                                : 'grey--text',attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFilter('is_new')}}},[_c('v-icon',[_vm._v("check_circle")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-combobox',{attrs:{"items":_vm.brands ? _vm.brands : [],"clearable":"","label":_vm.$t('Brand'),"item-text":"name","dense":""},on:{"change":_vm.updateBrandFilter,"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"clear",undefined,$event.key,undefined)){ return null; }return _vm.updateBrandFilter.apply(null, arguments)}},model:{value:(_vm.selectedBrand),callback:function ($$v) {_vm.selectedBrand=$$v},expression:"selectedBrand"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }